import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Recommendations = () => (
  <Layout>
    <Seo title="Empfehlungen" />
    <div className="font-courier w-11/12 md:w-4/5 mx-auto my-4 rounded shadow p-4">
      <h1 className="text-xl font-bold underline py-4 px-2">Empfehlungen:</h1>
      <div className="divide-y divide-gray-500">
        <p className="p-2 text-gray-800">
          Klaus & Petra Bensmann Lederschneiderei Bad Hindelang <br />
          <Link className="text-blue-500 px-2" to="https://www.kb-leder.de">
            www.kb-leder.de
          </Link>
          info@kb-leder.de{" "}
          <span className="float-right">Tel. 08324-9539702</span>
        </p>
        <p className="p-2 text-gray-800">
          Gebrauchtmaschinen Bernhard Schmitter Rosenheim <br />
          bernie.schmitter@t-online.de
          <span className="float-right">Tel. 08031-13126</span>
        </p>
        <p className="p-2 text-gray-800">
          Messerschmiede Noichl Michael <br />
          <Link
            className="text-blue-500 px-2"
            to="https://www.messer-noichl.de"
          >
            www.messer-noichl.de
          </Link>
          michael.noichl@vodafone.de
        </p>
        <p className="p-2 text-gray-800">
          Kolani Guitars Rainer Kolanowski Halle/Saale <br />
          <Link
            className="text-blue-500 px-2"
            to="http://www.kolani-gitarren.de"
          >
            www.kolani-gitarren.de
          </Link>
          r.kolanowski@t-online.de
        </p>
        <p className="p-2 text-gray-800">
          Diese Empfehlungen sind reiner freundschaftlicher Idealismus und haben
          keine finanziellen Hintergründe.
        </p>
      </div>
    </div>
  </Layout>
)
export default Recommendations
